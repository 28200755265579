import React, { useState, useEffect } from 'react';
import Flag from 'react-world-flags';
import { useNavigate } from 'react-router-dom';
import './FamousCountries.css';
import './country_list.css';
import { ArrowRightRounded } from '@mui/icons-material';
import FAQ from '../FAQ/FAQ';
import Footer from '../Footer/Footer';
import { Bars } from 'react-loader-spinner';
import AsiaImg from '../../../Assets/Asiaa_region_icons-16-removebg-preview.png';
import Europe from '../../../Assets/Europe_region_icons-15-removebg-preview.png';
import MiddleEast from '../../../Assets/MiddleEast_region_icons-17-removebg-preview.png';
import America from '../../../Assets/LatinAmerica_region_icons-18-removebg-preview.png';
import EastIcon from '@mui/icons-material/East';
import Balkans from '../../../Assets/Balkans-21-removebg-preview.png';
import Oceania from '../../../Assets/oceania_region_icons-14-removebg-preview.png';
import Caucasus from '../../../Assets/Caucasus-22-removebg-preview.png'
import Caribbean from '../../../Assets/caribbean_region_icons-19-removebg-preview.png'
import FlagNetherlandsAntilles from '../../../Assets/Netherlands Antilles.png'
import KosovoFlag from '../../../Assets/Flag-Kosovo.png'

const Country_List = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const [countryList, setCountryList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [iso2Country, setIso2Country] = useState("");

    // For opening the page to the top 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchCountryList = async () => {
            const requestOptions = {
                method: "POST",
                redirect: "follow"
            };

            try {
                const response = await fetch(`${REACT_APP_BASE_URL}/get_all_countries`, requestOptions);
                const result = await response.json();
                setCountryList(result.countries);
                setIso2Country(result.countries[0].iso2);
                localStorage.setItem("Iso2Country", result.countries[0].iso2)
                result.countries.forEach(country => {
                    // console.log(country.iso2, "------------------------country iso2");
                });


            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCountryList();
    }, []);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = countryList.filter((item) => {
        const name = String(item.name || '');
        return name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleCountryNameClick = (countryCode) => {
        localStorage.setItem("SelectedCountry", countryCode);
        navigate("/country_plans");
        window.scrollTo(0, 0);
    };

    const continentList = [
        { code: "EUR", name: "EUROPE+ Travel eSIM", content: '36', img: Europe },
        { code: "APC", name: "ASIA+ Travel eSIM", content: '12', img: AsiaImg },
        { code: "MEA", name: "MIDDLE EAST+ Travel eSIM", content: '8', img: MiddleEast },
        { code: "LAT", name: "LATIN AMERICA+ Travel eSIM", content: '17', img: America },
        { code: "BLK", name: "BALKANS+ Travel eSIM", content: '0', img: Balkans },
        { code: "OC", name: "OCEANIA+ Travel eSIM", content: '2', img: Oceania },
        { code: "CAU", name: "CAUCASUS+ Travel eSIM", content: '0', img: Caucasus },
        { code: "CRB", name: "CARIBBEAN+ Travel eSIM", content: '0', img: Caribbean },
    ];

    const handleNavigateRegionCountry = (RegionName, code) => {
        localStorage.setItem('regionCode', code)
        localStorage.setItem('RegionName', RegionName)

        navigate("/region_country_list");
        window.scrollTo(0, 0);
    }

    const handleNavigateRegionPlan = (name, code) => {
        localStorage.setItem('RegionName', name);
        localStorage.setItem('regionCode', code)
        navigate("/region_plans");

    }

    return (
        <>
            <div className='RegionPageList'>
                <div className='headings' >
                    <h1>Get mobile data wherever you travel.</h1>
                    <h2>Right away. Super simple. Best price.</h2>
                </div>
                
                <h2 className='heading-text'>List of all Regions</h2>
               

                <div className='AllCountries'>
                    {continentList.map((continent, index) => {
                        const [namePart1, namePart2] = continent.name.split('+');
                        return (
                            <div key={index} className='CountryNameCard' style={{ transform: 'scale(.85)' }} >
                                <h3>
                                    <span className="name-part1">{namePart1}+</span>
                                    <br />
                                    <span className="name-part2">{namePart2}</span>
                                </h3>
                                <img src={continent.img} alt={continent.name} className='continenet_img' />
                                <a
                                    onClick={(e) => { handleNavigateRegionCountry(namePart1, continent.code) }}
                                >
                                    Includes {continent.content} Countries
                                </a>

                                <button onClick={() => handleNavigateRegionPlan(namePart1, continent.code)} style={{ cursor: 'pointer' }} className="view-all-plans">
                                    View All Plans <EastIcon />
                                </button>
                            </div>
                        );
                    })}
                </div>


                <>
                    <div className='AllRegions'>
                        {continentList.map((continent, index) => {
                            const [namePart1, namePart2] = continent.name.split('+');
                            return (
                                <div key={index} className='RegionCard' onClick={() => handleNavigateRegionPlan(namePart1, continent.code)} >
                                    <div className='Names' >
                                        <h4>{namePart1}+</h4>
                                        <p onClick={(e) => { e.stopPropagation(); handleNavigateRegionCountry(namePart1, continent.code) }} >{namePart2} {continent.content} Countries </p>
                                    </div>

                                    <ArrowRightRounded style={{ color: '#292B2E', fontSize: '50px' }} />
                                </div>
                            )
                        })}
                    </div>
                </>



            </div>

            <div className='FamousCountries'>
                <h2 className='heading-text'>List of all Countries</h2>

                <center>
                    <div style={{ position: 'relative' }}>
                        <input
                            type="text"
                            placeholder="Search by country"
                            value={searchTerm}
                            onChange={handleChange}
                            className='serach_bar'
                        />
                    </div>
                </center>

                <div>
                    <div className='AllCountries'>
                        {loading ? (
                            <div style={{ width: '100%', height: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Bars
                                    height="30"
                                    width="30"
                                    color="#5050eb"
                                    visible={true}
                                />
                            </div>
                        ) : filteredData.length > 0 ? (
                            filteredData.map((country, index) => (
                                <div key={index} className='CountryNameCard' onClick={() => handleCountryNameClick(country.iso3)}>
                                    <Flag code={country.iso3} id='Flag' fallback={<span> {country.iso3 == "ANT" ? 
                                        (<img id='Flag' src={FlagNetherlandsAntilles} alt="" />): (<img id='Flag' src={KosovoFlag} alt="" />)}</span>} />
                                    <h3>{country.name}</h3>
                                    <ArrowRightRounded style={{ color: '#292B2E', fontSize: '40px' }} />
                                </div>
                            ))
                        ) : (
                            <h3 style={{ textAlign: 'center' }}>No countries available</h3>
                        )}
                    </div>
                </div>
            </div>

            <div id='searchFaq'>
                <FAQ />
            </div>

            <Footer />
        </>
    );
};

export default Country_List;
