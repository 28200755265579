import React, { useEffect, useState } from 'react';
import Flag from 'react-world-flags';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './FamousRegions.css';
import AsiaImg from '../../../Assets/Asiaa_region_icons-16-removebg-preview.png';
import Europe from '../../../Assets/Europe_region_icons-15-removebg-preview.png';
import MiddleEast from '../../../Assets/MiddleEast_region_icons-17-removebg-preview.png';
import America from '../../../Assets/LatinAmerica_region_icons-18-removebg-preview.png';
import EastIcon from '@mui/icons-material/East';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ArrowRightRounded } from '@mui/icons-material';
import { Button } from '@mui/material';



const FamousRegions = () => {

    const navigate = useNavigate();
    const customId = "forNotShowingMultipleToast"
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    

    // Mapping continents to representative country codes
    const continentList = [
        { code: "EUR", name: "EUROPE+ Travel eSIM", content: '36', img: Europe },
        { code: "APC", name: "ASIA+ Travel eSIM", content: '12', img: AsiaImg },
        { code: "MEA", name: "MIDDLE EAST+ Travel eSIM", content: '8', img: MiddleEast },
        { code: "LAT", name: "LATIN AMERICA+ Travel eSIM", content: '17', img: America },
    ];

    const [searchTerm, setSearchTerm] = useState("");

    const filteredData = continentList.filter(continent =>
        continent.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Update the screen size dynamically on resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const dataToShow = isMobile ? filteredData.slice(0, 2) : filteredData;

    const handleNavigateRegion = () => {
        toast.info("Regions plans are coming soon.", {
            toastId: customId,
            autoClose: 2000,
            position: 'top-right'
        })
    }


    const handleNavigateRegionCountry = (RegionName, code) => {
        localStorage.setItem('regionCode', code)
        localStorage.setItem('RegionName', RegionName)

        navigate("/region_country_list");
        window.scrollTo(0, 0);
    }

    const handleNavigateRegionPlan = (name, code) => {
        localStorage.setItem('RegionName', name);
        localStorage.setItem('regionCode', code)
        navigate("/region_plans");

    }

    const handleNavigate = () => {
        navigate("/all_countries&regions");
    }

    // for mobile view show the 2 cards
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const maxCardsToShow = windowWidth < 520 ? 2 : filteredData.length;


    return (
        <>
            <div className='FamousRegions'>

                <h3>Popular Regions <br />
                    <span className='one_liner'> One SIM for Multiple Countries</span>
                </h3>

                <div className='AllRegions'>
                    {dataToShow.map((continent, index) => {
                        const [namePart1, namePart2] = continent.name.split('+');
                        return (
                            <div key={index} className='RegionCard' onClick={() => handleNavigateRegionPlan(namePart1, continent.code)} >
                                <div className='Names' >
                                    <h4>{namePart1}+</h4>
                                    <p onClick={(e) => { e.stopPropagation(); handleNavigateRegionCountry(namePart1, continent.code) }} >{namePart2} {continent.content} Countries </p>
                                </div>

                                <ArrowRightRounded style={{ color: '#292B2E', fontSize: '50px' }} />
                            </div>
                        )
                    })}
                </div>

                <center>

                    <Button
                        onClick={() => handleNavigate()}
                        style={{
                            fontSize: "15px",
                            color: "white",
                            backgroundColor: "#00a1e4",
                            borderRadius: "30px",
                            marginTop: '30px',
                            padding: '7px 15px',
                        }}
                    >
                        Show All Regions +
                    </Button>

                </center>
            </div>
        </>
    );
}

export default FamousRegions;




